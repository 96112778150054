import React from "react"

import "./Services.css";
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import computerImg from "../../assets/images/computer.png";
import phoneImg from "../../assets/images/phone.png";
import questionImg from "../../assets/images/question.png";
 
const Services = () => (
    <div id="services-container">
        <div className="d-flex justify-content-center">
            <span id="title">WHAT I DO</span>
        </div>
        <div id="services-wrapper" class="row">
            <Col md={4} xs={12}>
                <div className="row">
                <div class="circle">
                    <div class="row">
                        <img className="circle-img" src={computerImg} alt="" />
                    </div>
                    <div className="circle-text">Web Design</div> 
                </div>
                </div>

                <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus efficitur tellus, a condimentum augue iaculis at. Etiam ullamcorper pharetra cursus. Vestibulum sed eleifend lacus, a porta ligula. In tempor sapien non felis suscipit, at rhoncus nulla tincidunt. Suspendisse bibendum lacinia consequat. 
                </div>

            </Col>
                <Col md={4} xs={12}>
                    <div className="row">
                        <div class="circle">
                            <div class="row"><img className="circle-img" src={phoneImg} alt="" /></div>
                            <span className="circle-text">App Design</span>
                        </div>
                    </div>
    
                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pel
                        lentesque finibus efficitur tellus, a condimentum augue iaculis at. Etiam ullamcorper pharetra cursus. Vestibulum sed eleifend lacus, a porta ligula. In tempor sapien non felis suscipit, at rhoncus nulla
                        tincidunt. Suspendisse bibendum lacinia consequat.  
                     </div>
                </Col>
                <Col md={4} xs={12}>
                    <div className="row">
                        <div class="circle">
                            <div class="row">
                                <img className="circle-img" src={questionImg} alt="" />
                            </div>
                            <span className="circle-text">Consultancy</span>
                        </div>
                    </div>

                    <div className="text">Lor
                    em ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus efficitur tellus, a condimentum augue iaculis at. Etiam ullamcorper pharetra cursus. Vestibulum sed eleifend lacus, a porta ligula. In tempor sapien non felis suscipit, at rhoncus nulla tincidunt. Suspendisse bibendum lacinia consequat. 
                    </div>
                </Col>
        </div>
    </div>
);

export default Services;