import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyDBQhVxqcfWX7V-rKIAUkP_WBFfkRjpClw",
    authDomain: "vkunev-web.firebaseapp.com",
    databaseURL: "https://vkunev-web.firebaseio.com",
    projectId: "vkunev-web",
    storageBucket: "vkunev-web.appspot.com",
    messagingSenderId: "558602590446",
    appId: "1:558602590446:web:cb78e71e89cd88e1c63bff",
    measurementId: "G-3RJX9FD0L3"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export default firebase;