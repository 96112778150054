
import React from 'react';

import "./Contact.css";
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { Formik } from "formik";

import locateImg from "../../assets/images/gps.png";
import mailImg from "../../assets/images/mail.png";

import firebase from "../../firebase";

const saveMessage = (value) => {
    const { fullName, email, mobileNumber, message} = value;
    firebase.firestore().collection("contact-from").add({
        fullName,
        email,
        mobileNumber,
        message
    })
}

const Contact = () => (
    <Container>
        <Row>
            <Col sm={12} md={6}>
                <div id="contact-title">Send Your Message</div>
                <div id="contact-details">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque finibus efficitur tellus, a condimentum augue iaculis at.
                </div>
                <Formik
                    initialValues={{
                        fullName: "",
                        email: "",
                        mobileNumber: "",
                        message: "",
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true);
                        // Save message
                        saveMessage(values);
                        // Resets form after submission is complete
                        resetForm();
                        // Sets setSubmitting to false after form is reset
                        setSubmitting(false);
                    }}
                >
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        name="fullName"
                                        className="contact-input" 
                                        placeholder="Your name" 
                                        onChange={handleChange}
                                        value={values.fullName}
                                        />
                                </Form.Group>

                                <Row>
                                    <Col xs={6} md={6}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control 
                                            type="email" 
                                            className="contact-input" 
                                            name="email"
                                            placeholder="Email" 
                                            onChange={handleChange}
                                            value={values.email}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control 
                                            name="mobileNumber" 
                                            className="contact-input" 
                                            type="text" 
                                            placeholder="Mobile number" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mobileNumber}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                    <Form.Control 
                                        name="message" 
                                        className="contact-input" 
                                        as="textarea" 
                                        rows="3"  
                                        placeholder="Your message" 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.message}/>
                                <div id="contact-button-wrapper">
                                    <Button type="submit" id="contact-button" variant="primary" size="lg" disabled={isSubmitting} >
                                            Submit Now
                                    </Button>
                                </div>
                            </Form>
                        )}
                </Formik>
            </Col >
            <Col sm={12} md={6}>
                <Row>
                    <div id="contact-info-title">Contact Info.</div>
                </Row>
                <Container id="contact-items">
                    <Row className="p-2">
                        <Col xs={5} md={3}>
                            <div className="img-contacts"><Image src={locateImg} width="50px" alt=""></Image></div>
                        </Col>
                        <Col xs={7} md={4}>
                            <div>Meet me in Sofia, Bulgaria</div>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        <Col xs={5} md={3}>
                            <div className="img-contacts">
                                <Image src={mailImg} width="50px" alt=""></Image>
                            </div>
                        </Col>
                        <Col xs={7} md={4}>
                            <div>myemail@mai.com</div>
                        </Col>

                    </Row>
                </Container>

            </Col>
        </Row>

    </Container>
);

export default Contact;