import React from "react";
import { Row, Col, Image } from "react-bootstrap";

import egineeringImg from "../../assets/images/engineering.jpg";
import mobileAppImg from "../../assets/images/mobileapp.jpg";
import webdesignImg from "../../assets/images/webdesign.jpg";
import sketchImg from "../../assets/images/sketch.jpg";

import "./Works.css";

const Works = () => (
    <div id="works-container">
        <div className="d-flex justify-content-center">
            <span id="title-works">WHAT I DID</span>
        </div>
        <Row>
            <Col xs={6} md={6}>
                <Image id="image-works1" src={egineeringImg} rounded alt="" />
            </Col>
            <Col xs={6} md={6}>
                <Image id="image-works2" src={mobileAppImg} rounded alt="" />
            </Col>
        </Row>
        <Row>
            <Col xs={6} md={6}>
                <Image id="image-works3" src={webdesignImg} rounded alt="" />
            </Col>
            <Col xs={6} md={6}>
                <Image id="image-works4" src={sketchImg} rounded alt="" />
            </Col>
        </Row>
    </div>
);

export default Works;