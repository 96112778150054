import React from 'react';

import Header from "../../containers/Header/Header";
import Jumbothrone from '../../containers/Jumbothrone/Jumbothrone';
import Services from '../../containers/Services/Services';
import Works from '../../containers/Works/Works';
import Hire from '../../containers/Hire/Hire';
import Contact from '../../containers/Contact/Contact';
import Footer from '../../containers/Footer/Footer';



const HomePage = () => (
   <>
   <Header />
   <Jumbothrone />
   <Services />
   <Works />
   <Hire />
   <Contact />
   <Footer />
   </>
);

export default HomePage;