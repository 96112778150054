import React from "react"

import "./Hire.css";
import { Row, Col, Button } from "react-bootstrap";

const Hire = () => (
    <div id="hire-container">
        <div className="container">
            <Row>
                <Col xs={12} md={8} >
                    <div id="hire-me-title">Hire me for your awsome project</div>
                    <div id="hire-me-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pel lentesque finibus efficitur tellus, 
                        a condimentum augue iaculis at. Etiam ullamcorper pharetra cursus. 
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div id="hire-button-wrapper">
                        <Button id="hire-button" variant="primary" size="lg">
                            Hire me
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>

    </div>
);

export default Hire;