import React from "react";

import NavBar from '../../components/NavBar/NavBar';
import Logo from '../../components/Logo/Logo';
import Hamburger from '../../components/Hamburger/Hamburger';

import {Navbar} from "react-bootstrap"

import "./Header.css";

const Header = () => (
<Navbar bg="white" expand="lg">
  <Navbar.Brand href="#home">
    <Logo></Logo>
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" > 
    <Hamburger/>
  </Navbar.Toggle>
  <Navbar.Collapse id="basic-navbar-nav">
    <NavBar></NavBar>
  </Navbar.Collapse>
</Navbar>
);

export default Header;