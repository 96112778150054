import React from 'react';
import HomePage from './pages/home/HomePage';

function App() {
  return (
    <HomePage></HomePage>
  );
}

export default App;
