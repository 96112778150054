import React from "react";

import "./Hamburger.css";

const Hamburger = () => (
    
    <div id="hamburger-container" className="flex">
        <div className="bar"/>
        <div className="bar middle-bar"/>
        <div className="bar"/>
    </div>
);

export default Hamburger;