import React from "react";
import { Nav } from "react-bootstrap";
import "./NavBar.css";

const NavBar = () => (
  <div id="navbar-nav">
    <Nav className="justify-content-center" activeKey="/home">
      <Nav.Item className="link">
        <Nav.Link id="nav-item" className="nav-link active" href="/home">HOME</Nav.Link>
      </Nav.Item>
      <Nav.Item className="link">
        <Nav.Link id="nav-item"  className="nav-link" eventKey="link-1">SERVICES</Nav.Link>
      </Nav.Item>
      <Nav.Item className="link">
        <Nav.Link id="nav-item" className="nav-link" eventKey="link-2">WORKS</Nav.Link>
      </Nav.Item>
      <Nav.Item className="link">
        <Nav.Link id="nav-item" className="nav-link" eventKey="link-2">BLOG</Nav.Link>
      </Nav.Item>
    </Nav>
  </div>
);

export default NavBar;