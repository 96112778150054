import React from "react";

import jumboImage from "../../assets/images/jumbo.png";

import "./Jumbothrone.css";
import { Button } from "react-bootstrap";

const Jumbothrone = () => (
    <div id="jumbo-container">
        <div id="" className="row">
            <div id="text-container" className="col-md-12 col-lg-6">
                <div id="text-hello">
                    Hello my name is
                </div>
                <div id="text-name">
                    VELISLAV KUNEV.
                </div>
                <div id="text-details">
                    I am developer and sport enthusiast
                </div>
                <div id="quote-button-wrapper">
                    <Button id="quote-button" className="pull-right" variant="outline-primary" size="lg">
                        GET A QUOTE
                        </Button>
                </div>
            </div>
            <div id="image-container" className="col-6 d-none d-lg-block">
                <img src={jumboImage} alt="" />
            </div>
        </div>
    </div>
);

export default Jumbothrone;