import React from "react";

import "./Footer.css";
import { Container } from "react-bootstrap";

const Footer = () => (
    <div id="footer-container">
        <Container>
            <div id="copyright">© 2020 VKUNEV ALL RIGHTS RESERVED</div>
        </Container>
    </div>
);

export default Footer;

